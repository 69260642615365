// eslint-disable-next-line ts/ban-types
export default (flag: string, variant: 'test' | 'control' | string & {} = 'test') => {
  const { $posthog } = useNuxtApp()

  const isActive = ref(false)

  $posthog.onFeatureFlags(() => {
    isActive.value = $posthog.getFeatureFlag(flag) === variant
  })

  return {
    isActive,
  }
}
